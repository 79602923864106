@import "palettes";
@import "theme";
@import "mixins";

p {
  margin: 20px 0;
  @include trim-v-margins;
  text-align: justify;
  line-height: $spacing + 0.3;
}
